import React from "react"
import SystemSecurityHeroSection from "./SystemSecurityHeroSection/SystemSecurityHeroSection"
import SystemSecurityInfoSection from "./systemSecurityInfoSection/SystemSecurityInfoSection"
import SystemSecurityAdvantagesSection from "./systemSecurityAdvantagesSection/SystemSecurityAdvantagesSection"
import SystemSecuritySliderSection from "./SystemSecuritySliderSection/SystemSecuritySliderSection"

const SystemSecurityTemplate = ({
  systemSecurityHeroSectionImage,
  systemSecurityInfoImageSection,
}) => {
  return (
    <>
      <SystemSecurityHeroSection systemSecurityHeroSectionImage={systemSecurityHeroSectionImage} />
      <SystemSecurityInfoSection systemSecurityInfoImageSection={systemSecurityInfoImageSection} />
      <SystemSecurityAdvantagesSection />
      <SystemSecuritySliderSection />
    </>
  )
}

export default SystemSecurityTemplate
