import React from "react"
import { graphql } from "gatsby"
import SystemSecurityTemplate from "../modules/services/systemSecurity/SystemSecurity.template"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"

const OhranitelniSistemi = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"Охранителни системи"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <SystemSecurityTemplate
        systemSecurityInfoImageSection={
          data.systemSecurityInfoImageSection.edges
        }
        systemSecurityHeroSectionImage={
          data.systemSecurityHeroSectionImage.edges
        }
      />
    </Layout>
  )
}

export default OhranitelniSistemi

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    systemSecurityHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "systemSecurityHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    systemSecurityInfoImageSection: allFile(
      filter: { relativeDirectory: { eq: "systemSecurityInfoImageSection" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
