import React from "react"
import SystemSecurityHeroSectionStyle from "./SystemSecurityHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const SystemSecurityHeroSection = ({ systemSecurityHeroSectionImage }) => {
  return (
    <section className={SystemSecurityHeroSectionStyle.sod_hero_section}>
      <Img
        className={SystemSecurityHeroSectionStyle.image}
        fluid={systemSecurityHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={SystemSecurityHeroSectionStyle.title_wrapper}>
        <h1>Охранителни системи</h1>
      </div>
    </section>
  )
}

export default SystemSecurityHeroSection
