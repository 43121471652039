import React from "react"
import SystemSecurityAdvantagesSectionStyle from "./SystemSecurityAdvantagesSectionStyle.module.styl"
import Advantage from "../../../../common/packages/advantage/Advantage"

const SystemSecurityAdvantagesSection = props => {
  return (
    <section className={SystemSecurityAdvantagesSectionStyle.sod_advantages}>
      <div
        className={SystemSecurityAdvantagesSectionStyle.sod_advantages_content}
      >
        <h2>Защо да изберете тази услуга ?</h2>
        <div className="row">
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={1}
              advantage={
                "Денонощен дежурен център за мониторинг и технически екипи"
              }
            />{" "}
          </div>
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={2}
              advantage={"Гаранционно и следгаранционно обслужване"}
            />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={3}
              advantage={
                "Инсталация и поддръжка на всички видове охранителни системи"
              }
            />{" "}
          </div>{" "}
          <div className="col-lg-3 col-md-6 col-12">
            <Advantage
              number={4}
              advantage={"Инсталация на пожароизвестителни системи"}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default SystemSecurityAdvantagesSection
