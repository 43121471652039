import React from "react"
import SystemSecurityInfoSectionStyle from "./SystemSecurityInfoSectionStyle.module.styl"
import Img from "gatsby-image"

const SystemSecurityInfoSection = ({ systemSecurityInfoImageSection }) => {
  return (
    <section className={SystemSecurityInfoSectionStyle.sod_info_section}>
      <div className={SystemSecurityInfoSectionStyle.intro_section_content}>
        <Img
          className={SystemSecurityInfoSectionStyle.intro_img}
          fluid={systemSecurityInfoImageSection[0].node.childImageSharp.fluid}
        />
        <div className={SystemSecurityInfoSectionStyle.intro_text}>
          <span>Денонощният център за мониторинг</span> е изграден с най-модерна
          техника, гарантираща надеждност и стабилност. Мрежовата свързаност на
          центъра е осигурен чрез партньорство с най-големият доставчик на
          интернет в региона. Дежурните екипи на „Спешъл Сикюрити Груп“ ООД, са
          обучени да отстраняват и поддържат всички видове оборудване, което се
          предлага на нашите клиенти. Техниката, която фирмата използва в
          охранителната си дейност е с най-високо ниво на защита и ниски разходи
          по поддръжка. След изтичане на гаранционният период на техниката,
          фирмата предлага гъвкави абонаменти планове за поддръжка
        </div>
      </div>
    </section>
  )
}

export default SystemSecurityInfoSection
